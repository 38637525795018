import { PaymentRequest } from '@stripe/stripe-js'
import Big from 'big.js'
import {
  APPLE_PAY_NATIVE,
  APPLE_PAY_WEB,
  GOOGLE_PAY_NATIVE,
  GOOGLE_PAY_WEB,
} from 'config/paymentMethod'
import { useCallback, useEffect, useState } from 'react'
import useChannel from './useChannel'
import { logError } from 'utils/honeybadger'
import { UserPaymentMethod } from 'types/user'

interface Props {
  paymentMethod?: Pick<UserPaymentMethod, 'paymentId'>
  onFinishPayment: (
    paymentMethodId: string,
    paymentMethodType: string
  ) => void | Promise<void>
  onCancel: () => void
  onReceiveNativePayConfirm: () => void
}

export const useNativePay = ({
  onFinishPayment,
  paymentMethod,
  onCancel,
  onReceiveNativePayConfirm,
}: Props) => {
  const { transmitPayment } = useChannel()
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>()

  const handleNativePayment = useCallback(
    (paymentMethodIdFromNative: string) => {
      // istanbul ignore else
      if (paymentMethodIdFromNative) {
        onReceiveNativePayConfirm()

        const handleAddBalanceWithPMType = async (
          paymentMethodType: 'apple' | 'google'
        ) => {
          await onFinishPayment(paymentMethodIdFromNative, paymentMethodType)
        }

        if (paymentMethod?.paymentId === APPLE_PAY_NATIVE.paymentId) {
          handleAddBalanceWithPMType('apple').catch(logError)
          return
        }

        /* istanbul ignore else */
        if (paymentMethod?.paymentId === GOOGLE_PAY_NATIVE.paymentId) {
          handleAddBalanceWithPMType('google').catch(logError)
        }
      }
    },
    [paymentMethod?.paymentId, onFinishPayment, onReceiveNativePayConfirm]
  )

  // payAmount need to be in cent
  const checkIfPaymentIsAppleOrGoogle = useCallback(
    (paymentId: string, payAmount: string) => {
      if (
        paymentId === APPLE_PAY_NATIVE.paymentId ||
        paymentId === GOOGLE_PAY_NATIVE.paymentId
      ) {
        transmitPayment(
          {
            // amount sending through native channel need to be in dollar
            amount: Big(payAmount).div(100).toNumber(),
          },
          handleNativePayment
        )

        // there will be a popup overlay the app
        // so we don't need to disable the button
        onCancel()
        return true
      }

      if (
        paymentId === APPLE_PAY_WEB.paymentId ||
        paymentId === GOOGLE_PAY_WEB.paymentId
      ) {
        paymentRequest?.update({
          total: {
            amount: parseInt(payAmount),
            label: 'Add JoePass',
          },
        })

        paymentRequest?.show()
        return true
      }

      return false
    },
    [paymentRequest, transmitPayment, onCancel, handleNativePayment]
  )

  useEffect(() => {
    // apple pay web need to use token id
    // so we're listening on token event from stripe
    paymentRequest?.on('token', async (event) => {
      const handleAddBalanceWithPMType = async (
        paymentMethodType: 'apple' | 'google'
      ) => {
        await onFinishPayment(event.token.id, paymentMethodType)
        event.complete('success')
      }

      if (paymentMethod?.paymentId === APPLE_PAY_WEB.paymentId) {
        await handleAddBalanceWithPMType('apple')
        return
      }

      // istanbul ignore next
      if (paymentMethod?.paymentId === GOOGLE_PAY_WEB.paymentId) {
        await handleAddBalanceWithPMType('google')
      }
    })

    paymentRequest?.on('cancel', () => {
      onCancel()
    })

    return () => {
      paymentRequest?.off('token')
      paymentRequest?.off('cancel')
    }
  }, [onFinishPayment, onCancel, paymentMethod?.paymentId, paymentRequest])

  return {
    checkIfPaymentIsAppleOrGoogle,
    paymentRequest,
    setPaymentRequest,
  }
}
