import Big from 'big.js'
import { format } from 'date-fns'
import { useMemo } from 'react'
import { styled } from 'styles/stitches.config'
import { FailedPaymentTransaction } from 'types/api/failedPayment'
import { formatMoney } from 'utils/money'
import { pluralize } from 'utils/stringUtils'

const Container = styled('div', {
  padding: '$m 0',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '$xxs',
  '& + &': {
    borderTop: '1px solid $grey200'
  }
})

const PaymentDate = styled('span', {
  fontSize: '$body3',
  lineHeight: '$body3',
  color: '$grey800'
})

const PaymentName = styled('span', {
  fontSize: '$body1',
  lineHeight: '$body1',
  fontWeight: '$bold',
  color: '$grey1000'
})

const DetailContent = styled('div', {
  display: 'flex',
  flexDirection: 'row-reverse',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: '$body2',
  lineHeight: '$body2',
})

const Amount = styled('span', {
  fontWeight: '$bold',
  color: '$grey1000'
})

const Description = styled('span', {
  color: '$grey800'
})

const FailedPaymentDetail = ({
  created,
  name,
  total: {
    currencyAmount,
    currencyFraction
  },
  insufficientFundsPenaltyFee: {
    currencyAmount: penaltyFeeAmount,
    currencyFraction: penaltyFeeFraction
  },
  items
}: FailedPaymentTransaction) => {
  const description = useMemo(() => {
    if (name.includes('Order')) {
      // istanbul ignore next
      const itemsCount = items?.length || 0

      return pluralize(itemsCount, 'item_items')
    }

    if (name.includes('JoePass')) {
      return 'Add funds'
    }

    // istanbul ignore else
    if (name.toLowerCase().includes('gift card')) {
      return 'Add funds'
    }

    // istanbul ignore next
    return ''
  }, [items?.length, name])

  return (
    <Container>
      <PaymentDate>{format(new Date(created), 'EEEE MMM dd, KK:mm a')}</PaymentDate>
      <PaymentName>{name}</PaymentName>
      <DetailContent>
        <Amount>{formatMoney(Big(currencyAmount).div(currencyFraction).toNumber())}</Amount>
        {!!description && (
          <Description>{description}</Description>
        )}
      </DetailContent>
      <DetailContent>
        <Amount>{formatMoney(Big(penaltyFeeAmount).div(penaltyFeeFraction).toNumber())}</Amount>
        <Description>Penalty fee</Description>
      </DetailContent>
    </Container>
  )
}

export default FailedPaymentDetail
