import { THIRD_PARTY_PAYMENT_METHODS } from 'config/paymentMethod'
import { capitalize, upperCase } from 'lodash'
import {
  ExternalPaymentMethod,
  GetUserPaymentMethodsResponse,
  GiftCardPaymentMethod,
  UserSettingsResponse,
} from 'types/api'

import { UserPaymentMethod } from 'types/user'

export const getPaymentMethod = (
  paymentMethods: UserPaymentMethod[],
  defaultPaymentMethodId: string | null,
  selectedId?: string
) => {
  if (selectedId) {
    const alreadySelectedPayment = paymentMethods.find(
      (payment) => payment.paymentId === selectedId
    )

    if (!!alreadySelectedPayment) {
      return alreadySelectedPayment
    }
  }

  const companyGiftCardPm = paymentMethods.find(
    (payment) => !!payment.companyName
  )

  if (companyGiftCardPm) {
    return companyGiftCardPm
  }

  const joeBalancePmInData = paymentMethods.find(
    (payment) => payment.paymentId === 'joebucks'
  )

  if (!!joeBalancePmInData && !!joeBalancePmInData.amount) {
    return joeBalancePmInData
  }

  if (defaultPaymentMethodId) {
    const defaultPaymentMethod = paymentMethods.find(
      (payment) => payment.id === defaultPaymentMethodId
    )

    if (!!defaultPaymentMethod) {
      return defaultPaymentMethod
    }
  }

  const firstACHPmInData = paymentMethods.find(
    (payment) => payment.type === 'bank'
  )

  if (!!firstACHPmInData) {
    return firstACHPmInData
  }

  const cardPmInData = paymentMethods.find(
    (payment) => payment.type === 'card'
  )

  if (!!cardPmInData) {
    return cardPmInData
  }

  const firstPmInData = paymentMethods[0]

  if (!!firstPmInData) {
    return firstPmInData
  }
}

export const sortPaymentMethods = (
  paymentMethods: UserPaymentMethod[],
  defaultPaymentId: string | null
) => {
  paymentMethods.sort((a, b) => {
    // If one is "joebucks" and the other is not, move "joebucks" to the top
    if (a.type === 'joebucks' && b.type !== 'joebucks') return -1
    if (a.type !== 'joebucks' && b.type === 'joebucks') return 1

    // If neither is "joebucks", sort based on defaultPaymentId
    if (
      (defaultPaymentId && a.id?.startsWith(defaultPaymentId)) ||
      a.type === defaultPaymentId // For third-party payment methods
    )
      return -1
    if (
      (defaultPaymentId && b.id?.startsWith(defaultPaymentId)) ||
      b.type === defaultPaymentId // For third-party payment methods
    )
      return 1

    return 0
  })

  return paymentMethods
}

export const isCardOrBank = (paymentMethod: UserPaymentMethod) =>
  paymentMethod.type === 'card' || paymentMethod.type === 'bank'

export const isThirdPartyPaymentMethod = (
  paymentMethodType: UserPaymentMethod['type']
): paymentMethodType is 'apple' | 'google' =>
  THIRD_PARTY_PAYMENT_METHODS.map((pm) => pm.type).some(
    (type) => type === paymentMethodType
  )

export const isDefaultPaymentMethod = (
  paymentMethod: UserPaymentMethod,
  defaultId: string | null
) => {
  if (!defaultId) {
    return false
  }

  if (isThirdPartyPaymentMethod(paymentMethod.type)) {
    return paymentMethod.type === defaultId
  }

  return paymentMethod.id === defaultId
}

export const isGiftCardPaymentMethod = (
  paymentMethod: GetUserPaymentMethodsResponse['paymentMethods'][number]
): paymentMethod is GiftCardPaymentMethod => paymentMethod.type === 'joebucks'

export const normalizeGiftCardPaymentMethod = (
  paymentMethod: GiftCardPaymentMethod
): UserPaymentMethod => {
  const isJoeBucks = paymentMethod.id === 'joebucks'

  const payment: UserPaymentMethod = {
    displayName: '',
    paymentId: paymentMethod.id,
    type: paymentMethod.type,
    amount: paymentMethod.balance,
    name: isJoeBucks
      ? `JoePass ($${paymentMethod.balance})`
      : `${paymentMethod.companyName} Gift Card ($${paymentMethod.balance})`,
  }

  if (!isJoeBucks) {
    payment.companyName = paymentMethod.companyName
    payment.companyId = paymentMethod.companyId
    payment.giftCardImage = paymentMethod.giftCardImage
  }

  return payment
}

export const normalizeExternalPaymentMethod = (
  paymentMethod: ExternalPaymentMethod
): UserPaymentMethod => {
  const {
    accountType,
    expDate,
    institutionName,
    last4,
    paymentMethodId,
    type,
    id,
  } = paymentMethod

  if (type === 'card') {
    return {
      id,
      displayName: `${capitalize(accountType ?? '')} Card`,
      paymentId: paymentMethodId as string,
      name: `${capitalize(institutionName as string)} - ${last4}`,
      type,
      expDate,
    }
  }

  return {
    id,
    displayName: 'Bank Account',
    paymentId: paymentMethodId as string,
    name: `${upperCase(institutionName as string)} - ${last4}`,
    type,
  }
}

export const normalizePaymentMethod = (
  paymentMethod: GetUserPaymentMethodsResponse['paymentMethods'][number]
): UserPaymentMethod => {
  if (isGiftCardPaymentMethod(paymentMethod)) {
    return normalizeGiftCardPaymentMethod(paymentMethod)
  }

  return normalizeExternalPaymentMethod(paymentMethod)
}

export const mapPaymentMethods = (
  paymentMethods: GetUserPaymentMethodsResponse['paymentMethods'][number][]
) => {
  const joeBucks = paymentMethods.find(
    (pm) => isGiftCardPaymentMethod(pm) && pm.id === 'joebucks'
  ) as GiftCardPaymentMethod | undefined

  const giftCards = paymentMethods.filter(
    (pm) => pm.type === 'joebucks' && pm.id !== 'joebucks'
  ) as GiftCardPaymentMethod[]

  const externalPaymentMethods = paymentMethods.filter(
    (pm) => pm.type !== 'joebucks'
  ) as ExternalPaymentMethod[]

  return {
    joeBucks,
    giftCards,
    paymentMethods: externalPaymentMethods.filter(
      (pm) => pm.type === 'card' || pm.type === 'bank'
    ),
  }
}

export const getDefaultPaymentMethodId = (
  defaultPaymentMethodSetting: UserSettingsResponse['settings']['defaultPaymentMethod']
) => {
  if (!defaultPaymentMethodSetting || !defaultPaymentMethodSetting.enabled) {
    return null
  }

  const { paymentMethodId, type } = defaultPaymentMethodSetting

  if (type === 'card' || type === 'bank') {
    return paymentMethodId
  }

  return type
}

//TODO: Uncomment after fixing link bank account
// export const MIN_AMOUNT_CARD = 20
export const MIN_AMOUNT_CARD = 10
export const MIN_AMOUNT_BANK = 10
export const MIN_AMOUNT_THIRD_PARTY = 10

export const getMinAmount = (paymentMethod: UserPaymentMethod) => {
  if (paymentMethod.type === 'card') {
    return MIN_AMOUNT_CARD
  }

  if (paymentMethod.type === 'bank') {
    return MIN_AMOUNT_BANK
  }

  if (paymentMethod.type === 'apple' || paymentMethod.type === 'google') {
    return MIN_AMOUNT_THIRD_PARTY
  }

  return 0
}

export const isAmountValid = (
  amount: number,
  paymentMethod: UserPaymentMethod
) => {
  const minAmount = getMinAmount(paymentMethod)

  return amount >= minAmount
}
